import React from 'react'

function TermOfUse() {
  return (
    <div className='mt-28 px-8 py-4 text-sm'>
        <h2 className="p-2 font-semibold">TERMS OF USE</h2>
        <h3 className="p-2 font-semibold">INTRODUCTION</h3>
        <p>This website, i.e. https://www.jalvayu.co (“Website”) is operated by Returno fintech Private Limited, a company incorporated and existing under the Companies Act, 2013 (hereinafter referred as “Jalvayu” or “us” or “we” or “our”) for providing products made with temple waste flowers. These Terms (as defined below) were updated as on 12 March 2024.
These terms of use (“Terms”) govern the terms of usage of our Website. These Terms constitute an electronic document published in accordance with the provisions of the Information Technology Act, 2000 (“Act”) and the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 (“Rules”), as amended from time to time.
GENERAL
You can determine when these Terms were last revised by referring to the date at the top of these Terms. Jalvayu reserves the right to make any changes to these Terms at its sole discretion, subject to the Act and the Rules and it is your duty to periodically check the Terms for any such updates. By accessing the Website, you agree that you are using the Website solely at your discretion and in no way did Jalvayu induce or invite or solicit you to visit our Website. You should not modify or edit or alter or use any content from the Website in any manner to hamper the position of Jalvayu.
</p>

<h3 className="p-2 font-semibold">GENERAL</h3>
<p>You can determine when these Terms were last revised by referring to the date at the top of these Terms. Jalvayu reserves the right to make any changes to these Terms at its sole discretion, subject to the Act and the Rules and it is your duty to periodically check the Terms for any such updates. By accessing the Website, you agree that you are using the Website solely at your discretion and in no way did Jalvayu induce or invite or solicit you to visit our Website. You should not modify or edit or alter or use any content from the Website in any manner to hamper the position of Jalvayu.</p>

<h3 className="p-2 font-semibold">ELIGIBILITY AND CONSENT</h3>
<p>By accessing the Website, the user(s), or the parent or legal guardian of the user(s) who are Minors (as defined below), as the case may be (“User” or “Users” or “you” or “yours”), acknowledge that they have read, understood and agree to these Terms.
If the Users are minors, i.e. under the age of 18 (eighteen) years (“Minors”), such Minors must use the Website under the supervision of a parent or a legal guardian who agree to be bound by the Terms.
It is clarified that all references to “he”, “his”, “him” and “himself”, in the context of the User, shall be deemed to include references to Users of the opposite gender as well as Users who are legal or non-natural entities.
</p>
<h3 className="p-2 font-semibold">USE OF WEBSITE</h3>
<p>Subject to your acceptance of these Terms, we grant you a limited, revocable, personal, non-exclusive, non-transferable license to use the Website, which allows you to access and use the Website on mobile, tablets and desktop device (“Devices”) that you own or control. You may use the Website on the Devices solely for your own personal and non-commercial use.
<h4 className="p-2 font-semibold">Website:</h4>
The Website shall be best viewed on the latest version of Google Chrome, Firefox, Safari, Microsoft and are accessible on the Devices.
The Website is best accessed from the following operating systems, (a) Windows XP or above, (b) MAC OS 10.2 or above.
The contents of the Website are best viewed with the latest version of Adobe Flash Player. Jalvayu’s Website is compatible only with the above-mentioned software’s or programmes. Jalvayu shall not be obligated, at any point of time, to provide workable service for any Devices that are not recognized by Jalvayu or those instruments that may be purchased from any third-party which are not compatible with the Jalvayu’s Website.
Further, Jalvayu reserves the right to upgrade its Website from time to time to provide its service in the best possible manner.
</p>
<h4 className="p-2 font-semibold">Third-Party Links:</h4>
<p>The Website may contain links to websites or applications offered by third parties (“Third-Party Sites”). We don’t control or promote Third-Party Sites. Your use of any Third-Party Sites is entirely at your own risk and we are not responsible for any of the consequences faced by you for using these Third-Party Sites. We are not responsible for the practices employed by any websites or service linked to or from our Website, including the information or content contained within them. Jalvayu does not make any representations concerning the privacy practices or policies or terms of use of such Third-Party Sites, nor does it control or guarantee the accuracy, integrity, or quality of the information, data, text, software, music, sound, photographs, graphics, videos, promotional advertisements or other materials available on such Third-Party Sites. The inclusion or exclusion does not imply any endorsement by Jalvayu of the Third-Party Sites, the Third-Party Sites’ provider, or the information on such Third-Party Sites.
Our Website may contain third-party content which may have opinions and views from the person. Jlavayu.co shall not be responsible for such opinions or any claims resulting from them. Such contents from the third-party have been reproduced after taking prior consent from said party and all rights relating to such content will remain with such third-party. Further, you recognize and acknowledge that the ownership of all trademarks, copyright, logos, service marks and other intellectual property owned by any third-party shall continue to vest with such party and you agree and acknowledge that jalvayu.co shall not be liable and in no way be held responsible for any claims arising therefrom.
You hereby agree that Jlavayu.co has the right to change modify, suspend, or discontinue and/or eliminate any aspect(s), features or functionality of the Website as it deems fit at any time without notice. Jalvayu.co makes no commitment, express or implied, to maintain or continue any aspect of the Website. You agree that the Jalvayu shall not be liable to you or any third-party for any modification, suspension or discontinuance of the Website.
</p>
<h4 className="p-2 font-semibold">Your Account:</h4>
<p>The User will have to register with Jalvayu.co in order to access certain services or areas of the Website. With respect to any such registration, we may refuse to grant to you the User-name you request. Your User name and password are for your personal use only.
It shall be the sole responsibility of the User to maintain the confidentiality of their account, password, and to prevent unauthorized access.
In furtherance to the foregoing, any User who wishes to modify the details of his/her account can do so by clicking on the ‘Your Account’ section on the Website.
Lastly, the User acknowledges that they are using their account only for the purpose of placing orders of the products listed on our Website.
</p>
<h4 className="p-2 font-semibold">Limitations on quantity</h4>
<p>Jalvayu.co does not offer additional discounts on large orders of a single product or multiple products at all times. In addition, we reserve the right to limit quantities on orders placed by the same account, on orders placed by the same method of payment, and on orders that use the same billing or shipping address. We will notify you if such limits are applied. Jalvayu reserves the right to prohibit purchases of any products to resellers. Resellers are defined as a company or an individual that purchases goods with the intention of selling them rather than using them.</p>
<h4 className="p-2 font-semibold">Colours</h4>
<p>Jalvayu.co strives to display as accurately as possible the colours of the products shown on the Website; however, Jalvayu.co cannot and does not guarantee that your monitor’s display of any colour will be accurate.</p>
<h4 className="p-2 font-semibold">Availability, Errors & Inaccuracies</h4>
<p>Jalvayu’s acknowledgement of an order means that your order request has been received; it does not mean that your order has been accepted or shipped or that the price or availability of a product has been confirmed. Jalvayu makes a conscientious effort to describe and display its products accurately on the Website. Despite these efforts, a small number of products on the Website may be mispriced, described inaccurately or unavailable, and we may experience delays in updating information on the Website and in our advertising on other sites. As a result, we cannot and do not guarantee the accuracy or completeness of any information, including prices, product images, specifications, availability.
Jalvayu.co, reserves the right to change or update information and to correct errors, inaccuracies or omissions at any time without prior notice. We apologize for any inconvenience. If we determine that there were inaccuracies in our product information, we will cancel your order and notify you of such cancellation via email.
</p>

<h4 className="p-2 font-semibold">Rules for Promotions</h4>
<p>Any sweepstakes, contests, raffles, or other promotions (collectively, “Promotions”) made available through the Website may be governed by rules that are separate from these Terms. If you participate in any Promotions, please review the applicable rules as well as our Privacy Policy. If the rules for any Promotion is in conflict with these Terms, the Promotion’s rules will apply.</p>

<h4 className="p-2 font-semibold">Text Messages</h4>
<p>Your carrier’s standard message rates will apply to your entry or submission message, Jalvayu’s confirmation, and all subsequent text messages. Other charges may apply. All charges are billed by and payable to your mobile service provider.
Jalvayu will not be liable for any delays in the receipt of any text messages. Delivery is subject to effective transmission from your network operator.
Data obtained from you in connection with this text messaging service may include your mobile phone number, your carrier’s name, and the date time and content of your messages and other information you provide to Jalvayu as part of this service. Jalvayu may use this information to contact you and provide products you request from Jalvayu. Jalvayu may also use this information as described in the subscription list you’ve enrolled in. Jalvayu may use an automatic dialling system to deliver text messages to you.
</p>

<h3 className="p-2 font-semibold">CONTENT</h3>
<p>Jalvayu.co provides content on its Website which may be produced by us or by third-party. We are in no manner responsible to you for the accuracy, legitimacy and trueness of the information so hosted. We take reasonable care to ensure such accuracy but we are not responsible for the information so furnished. You agree to not hold us liable for the falsification of any such provided information.</p>

<h3 className="p-2 font-semibold">PRICING INFORMATION</h3>
<p>Jalvayu.co strives to provide accurate and correct information at all times. However, sometimes errors may occur.
Jalvayu.co can only confirm the actual price of the order once the order is placed.
Notwithstanding Clause 8 (Cancellation), we hold the right to cancel your order if the price of the product and/or product information is inaccurately listed due to some technical error. However, this Clause 10.3 would not apply in case the order has already been delivered.
In the event, where the price of the product and/or product information has been wrongly listed, Jalvayu will first contact you for instructions and only upon your explicit instruction to process the order, will Jalvayu deliver the order. In all other cases such an order shall be deemed to be not received.
Upon our sole discretion we may cease or discontinue listing a product on our Website. Further, we may also change the price and/or any other information about the product. Any such change may not be notified or intimated to you.
</p>

<h3 className="p-2 font-semibold">MISCELLANEOUS</h3>
<h4 className="p-2 font-semibold">Risk of loss</h4>
<p>Except as otherwise set forth herein, the risk of loss for and title to products purchased on the Website passes to the purchaser upon delivery by the carrier.</p>

<h4 className="p-2 font-semibold">Jurisdictional Issues</h4>
<p>The Website are controlled and operated by Jalvayu.co from Ajmer, and is not intended to subject Jalvayu to the laws or jurisdiction of any state, country or territory other than that of India. In choosing to access the Website, you do so on your own initiative and at your own risk, and you are responsible for complying with all local laws, rules and regulations. We may limit the Website availability, in whole or in part, to any person, geographic area or jurisdiction we choose, at any time and in our sole discretion.
 
 </p>

<h3 className="p-2 font-semibold">INTELLECTUAL PROPERTY</h3>
<p>Unless otherwise specifically mentioned in these Terms, in addition to the content, all of the content available through the Website (including the right to sue for passing off, design rights, reports, data, databases, tools, code, photographs, pictures, video, interfaces, web-pages, designs, text, graphics, images, information, software (“Software”), audio, User content and other media files, their selection and arrangement, materials and all other intellectual property rights) are owned by us or respective third-party or licensed to us by a third-party (“Our Content”). You acknowledge and accept that you are expressly prohibited from using Our Content except where we grant you a limited license to use Our Content in connection with the Website. Our name and logo ‘Jalvayu.co.’ and such others are our service mark. To the extent that there are trademarks, service marks and/or logos from other organisations on our Website, they are the trademarks, service marks and logos of those respective organisations. You are granted no general right or commercial license with respect to our trademarks, service marks, and logos or of the trademarks, service marks or logos of those other organisations.</p>

<h3 className="p-2 font-semibold">DELETING YOUR ACCOUNT</h3>
<p>Jalvayu.co reserves the right to terminate your account in case you are found in violation of the Terms.
Jalvayu.co will not be responsible for any losses arising out of such termination of account.
Consequences of termination: Upon the termination of your account, jalvayu.co shall be relieved of all future liabilities and obligations towards you.
</p>
<h3 className="p-2 font-semibold">CONDUCT OF THE USERS</h3>
<p>You hereby agree, undertake and covenant that, during the use of the Website, you shall not host, display, upload, modify, publish, transmit, update or share any information that:
is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, including bodily privacy, insulting or harassing on basis of gender, libellous, hateful or racially or ethnically objectionable, disparaging or encouraging money laundering or gambling, or otherwise unlawful in any manner whatsoever under the laws of India;
infringes any patent, trademark, copyright or other proprietary rights;
violates any law for the time being in force;
threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting other nation;
contains software virus or any other computer code, file or program designed to interrupt, destroy or limit the functionality of any computer resource;
is patently false and untrue, and is written or published in any form, with the intent to mislead or harass a person, entity or agency for financial gain or to cause any injury to any person; and
provides instructional information about illegal activities such as making or buying illegal weapons or any other objects, violating someone's privacy, or providing or creating computer viruses;
engages in commercial activities without jalvayu’s prior written consent such as engages in contests, sweepstakes, barter, advertising etc.;
interferes with another Users use and enjoyment of the Website;
You may not decompile, reverse engineer, or disassemble the contents of the Website, Our Content or Software or modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Website, Our Content or Software or remove any copyright, trademark registration, or other proprietary notices from the contents of the Website.
You will not (a) use the Website for commercial purposes of any kind, or (b) advertise or sell domain names or otherwise (whether or not for profit), or solicit others (including, without limitation, solicitations for contributions or donations) or use any public forum for commercial purposes of any kind, or (c) use the Website in any way that is unlawful, or harms jalvayu or any of their Representatives. (as defined below)
</p>
<h3 className="p-2 font-semibold">CONFIDENTIALITY</h3>
<p>As elaborated under the Privacy Policy, Jalvayu.co will keep all confidential information confidential, including your personal information, and shall not disclose it to anyone except as provided in the Terms and required by law, and shall ensure that such confidential information is protected with security measures and a degree of care that it would apply to its own confidential information. Jalvayu acknowledges that its employees, directors, agents and contractors (“Representatives”) shall use the confidential information only for the intended purpose for which it is provided. Jalvayu.co shall use all reasonable endeavors to ensure that its Representatives acknowledge and comply with the provisions of these Terms of confidentiality.</p>
<h3 className="p-2 font-semibold">PROPRIETARY RIGHTS</h3>
<p>The Website is protected by copyright laws as well as other intellectual property laws as applicable. Jalvayu and its licensors shall retain ownership in and to the Website and to all related intellectual property rights, including without limitation copyrights, trademarks, trade names, database rights and patents. You are granted only a limited right to use the Website subject to these Terms and no intellectual property rights are or will deemed to be transferred or licensed or assigned to you except as contemplated herein.</p>
<h3 className="p-2 font-semibold">PRIVACY POLICY </h3>
<p>The Users agree that they have read through the privacy policy (“Privacy Policy”) that is provided on the Website and have understood and agreed to the terms incorporated therein. The Users consent to the use of their personal and non-personal information by Jalvayu.co in accordance with the terms of as set forth in the Privacy Policy.</p>
<h3 className="p-2 font-semibold">INDEMNIFICATION</h3>
<p>You agree to indemnify and hold harmless jalvayu, its owners, licensors, suppliers, affiliates, subsidiaries, group companies (as applicable) and their Representatives, from any claims, suits, proceedings, disputes, demands, liabilities, damages, loss, costs, demand, or actions including reasonable attorneys' fees, made by any third-party or penalty imposed due to or arising out of the User’s breach of these Terms, Privacy Policy and other policies, or the User’s violation of any law, rules or regulations or the rights (including infringement of any intellectual property rights) of a third-party. </p>

<h3 className="p-2 font-semibold">FEEDBACK AND SURVEYS </h3>
<p>Any feedback you provide on the Website regarding the listed products, through any surveys undertaken by us shall be deemed to be non-confidential. The Website shall be free to use such non-confidential information on an unrestricted basis. Further, by submitting your feedback and survey data, you represent and warrant that (i) your feedback and survey data does not contain confidential or proprietary information of you or of third parties; (ii) Jalvayu.co is not under any obligation of confidentiality, express or implied, with respect to the feedback and survey data; and (iv) you are not entitled to any compensation or reimbursement of any kind from jalvayu.co for the feedback or survey data under any circumstances, unless specified.</p>

<h3 className="p-2 font-semibold">ASSIGNMENT </h3>
<p>Jalvayu.co reserves the right, at its own discretion, to freely assign and transfer the rights and obligations under these Terms to any third-party.
 
 </p>

<h3 className="p-2 font-semibold">TERMINATION </h3>
<p>These Terms are effective unless and until terminated by either you or Jalvayu.co.
You may terminate the Terms at any time, provided that you discontinue any further use of the Website. Jalvayu.co may terminate the Terms at any time and may do so without any prior notice.
</p>

<h3 className="p-2 font-semibold">DISCLAIMER OF WARRANTIES </h3>
<p>THE SERVICE AND ALL INFORMATION, CONTENT, MATERIALS ON OR OTHERWISE MADE AVAILABLE TO THE USER THROUGH THE WEBSITE ARE PROVIDED BY JALVAYU.CO ON AN "AS IS," "AS AVAILABLE" BASIS, WITHOUT REPRESENTATIONS OR WARRANTIES OF ANY KIND. JALVAYU MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE SERVICE, THE ACCURACY OR COMPLETENESS OF THE CONTENTS AND THE ACCURACY OF THE INFORMATION. JALVAYU SHALL HAVE NO RESPONSIBILITY FOR ANY DAMAGE TO YOUR DEVICES OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY CONTENT, MATERIALS, DOCUMENT OR INFORMATION. THE USER EXPRESSLY AGREES THAT THE USE OF THE SERVICE IS AT THE USER’S SOLE RISK. JALVAYU WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THE WEBSITE OR THE CONTENTS INCLUDING, WITHOUT LIMITATION, DIRECT, INDIRECT, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING. TO THE FULL EXTENT PERMITTED BY LAW, JALVAYU DISCLAIMS ANY AND ALL REPRESENTATIONS AND WARRANTIES WITH RESPECT TO THE WEBSITE AND ITS CONTENTS, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF TITLE AND FITNESS FOR A PARTICULAR PURPOSE OR USE.</p>


<h3 className="p-2 font-semibold">LIMITATION OF LIABILITY </h3>
<p>IN NO EVENT SHALL JALVAYU, OR ITS REPRESENTATIVES BE LIABLE FOR ANY SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM THE USER’S ACCESS TO AND USE OF THE WEBSITE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF THE SECURE SERVERS AND/OR ANY AND ALL PERSONAL AND/OR BUSINESS AND/OR FINANCIAL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE WEBSITE; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH THE WEBSITE BY ANY THIRD-PARTY; AND/OR (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT JALVAYU IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SUBJECT TO THE ABOVE AND NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED IN THESE TERMS, THE MAXIMUM AGGREGATE LIABILITY (WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR EQUITY) OF JALVAYU VIS-À-VIS ANY EMPLOYER, REGARDLESS OF THE FORM OF CLAIM, SHALL BE LIMITED TO THE AGGREGATE OF ANY FEES/AMOUNT PAID BY THE USER TO JALVAYU. THE FOREGOING LIMITATIONS OF LIABILITY WILL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY HEREIN.</p>


<h3 className="p-2 font-semibold">SEVERABILITY AND WAIVER </h3>
<p>These Terms, the Privacy Policy and other referenced material herein or on the Website, are the entire agreement between you and jalvayu.co with respect to the Website as entailed herein, and supersede all prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and jalvayu with respect to the service and govern the future relationship. If any provision of the Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the Terms will otherwise remain in full force and effect and enforceable. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder.</p>

<h3 className="p-2 font-semibold">JURISDICTION </h3>
<p>These Terms shall be governed by and interpreted and construed in accordance with the laws of India. The place of jurisdiction shall exclusively be in Ajmer, Rajasthan India. In the event of any dispute arising out of these Terms the same shall be settled by a binding arbitration conducted by a sole arbitrator, appointed jointly by both parties and governed by the Arbitration and Conciliation Act, 1996. The venue of arbitration shall be Ajmer, Rajasthan India and the language used shall be English</p>

    </div>
  )
}

export default TermOfUse
