import Home from "./Home";
import Header from "./Header";
import Footer from "./Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./PrivacyPolicy";
import TermOfUse from "./TermOfUse";
import Contact from "./Contact";

export default function App() {
  return (
    <Router>
        <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/term-of-use" element={<TermOfUse/>} />
      </Routes>
        <Footer />
    </Router>
  );
}