
import { Link } from 'react-router-dom';
import logo from './images/Jalvayu.svg';

function Header() {
  return (
    <div className="fixed z-10 top-0 bg-white flex w-screen justify-between items-center px-1 md:px-8 py-2" id="home">
      <Link to="/">
        <img src={logo} width="100px" alt="Jalvayu Logo" />
      </Link>
    
      <div className="px-1 md:px-3 py-6">
        <Link to="/" className="p-2">
          <button className="text-gray-800 font-semibold text-xl">Home</button>
        </Link>
        <a href="#products" className="p-2">
          <button className="text-gray-700 font-semibold text-xl">Products</button>
        </a>

        <Link to="/contact" className="p-2">
          <button className="text-gray-800 font-semibold text-xl">Contact</button>
        </Link>
        
      </div>
    </div>
  );
}

export default Header;
