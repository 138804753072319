import React from "react";

function Contact() {


  return (
    <div className='mt-32 mb-11 w-96 p-7 bg-neutral-300 mx-auto rounded-lg'>
      <div className=" ">
        <h2 className="text-center p-2 font-semibold">Contact us</h2>
       
        <div className="text-xl text-center">Email :- jalvayu6@gmail.com</div>
            
      </div>
    </div>
  );
}

export default Contact;