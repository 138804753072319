
import Image from 'react-image-webp';

function Products() {

  return (
    <div className=''>
      <h2 className="text-center font-bold text-xl pt-12">OUR PRODUCTS</h2>
      <div className="rounded-md bg-white flex gap-4" id="products">
      </div>

      {/* <div className="p-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"> */}

      <div className="p-16 flex gap-4 mx-auto md:w-[80%] flex-wrap md:flex-nowrap">

        <div className="border-slate-950 border-2 rounded-lg shadow-md shadow-slate-500">
          <Image
           webp={require('./images/a.webp')}
          />
          <div className="p-5 flex justify-center">
            <a className='bg-teal-700 w-full  py-2 px-7 bottom-0 rounded-lg text-white font-bold' href="https://www.amazon.in/Jalvayu-Premium-Chandan-Incense-Fragrance/dp/B0D14YJWKW/ref=sr_1_4?crid=25N423OUUVLUY&dib=eyJ2IjoiMSJ9.aOWgKEzo1pJe0kXUJFEMqhigkUbs9nSb3VPlx69R_YSEeo5EVHiU7RG9JuUViY5am78ovzirGl_w2BfUNnIHz3-cw80i2_HBZiqk4CnfEPiekz_PcaThM7s2DqqzNIexn7WMY3u056n49MihuwhcTgh4KrNZMahgMkCW_Ao3J6BYnqrfdyV72lLTJeXPiC9S5J1uoNM6DnHKEeWx7lRtFexvvqer1b7tX8j6AX3h0AYYhrJtZ4xC5V0RZ5IMjtH62QR-kBP9nUwfs_AKJVIpVeLayH_mShzWmqpCovwFQos.fpAwXTv-C04FKH3t-f0gqvtugvwKnpOi74fotLPj0PU&dib_tag=se&keywords=jalvayu&qid=1712912129&sprefix=jalvayu%2Caps%2C226&sr=8-4" target='_blank'><button className="w-full">Buy Now</button></a>
          </div>
        </div>


        <div className="border-slate-950 border-2 rounded-lg shadow-md shadow-slate-500">
          <Image
           webp={require('./images/b.webp')}
          />
          <div className="p-5 flex justify-center">
            <a className='bg-teal-700 w-full  py-2 px-7 bottom-0 rounded-lg text-white font-bold' href="https://www.amazon.in/Jalvayu-Premium-Incense-Natural-Agarbatti/dp/B0D14C1RVN/ref=sr_1_3?crid=25N423OUUVLUY&dib=eyJ2IjoiMSJ9.aOWgKEzo1pJe0kXUJFEMqhigkUbs9nSb3VPlx69R_YSEeo5EVHiU7RG9JuUViY5am78ovzirGl_w2BfUNnIHz3-cw80i2_HBZiqk4CnfEPiekz_PcaThM7s2DqqzNIexn7WMY3u056n49MihuwhcTgh4KrNZMahgMkCW_Ao3J6BYnqrfdyV72lLTJeXPiC9S5J1uoNM6DnHKEeWx7lRtFexvvqer1b7tX8j6AX3h0AYYhrJtZ4xC5V0RZ5IMjtH62QR-kBP9nUwfs_AKJVIpVeLayH_mShzWmqpCovwFQos.fpAwXTv-C04FKH3t-f0gqvtugvwKnpOi74fotLPj0PU&dib_tag=se&keywords=jalvayu&qid=1712912129&sprefix=jalvayu%2Caps%2C226&sr=8-3" target='_blank'><button className="w-full">Buy Now</button></a>
          </div>
        </div>



        <div className="border-slate-950 border-2 rounded-lg shadow-md shadow-slate-500">
          <Image
            webp={require('./images/c.webp')}
          />
          <div className="p-5 flex justify-center">
            <a className='bg-teal-700 w-full  py-2 px-7 bottom-0 rounded-lg text-white font-bold' href="https://www.amazon.in/Jalvayu-Premium-Chandan-Agarbatti-Fragrance/dp/B0D14X6Y86/ref=sr_1_7?crid=25N423OUUVLUY&dib=eyJ2IjoiMSJ9.aOWgKEzo1pJe0kXUJFEMqhigkUbs9nSb3VPlx69R_YSEeo5EVHiU7RG9JuUViY5am78ovzirGl_w2BfUNnIHz3-cw80i2_HBZiqk4CnfEPiekz_PcaThM7s2DqqzNIexn7WMY3u056n49MihuwhcTgh4KrNZMahgMkCW_Ao3J6BYnqrfdyV72lLTJeXPiC9S5J1uoNM6DnHKEeWx7lRtFexvvqer1b7tX8j6AX3h0AYYhrJtZ4xC5V0RZ5IMjtH62QR-kBP9nUwfs_AKJVIpVeLayH_mShzWmqpCovwFQos.fpAwXTv-C04FKH3t-f0gqvtugvwKnpOi74fotLPj0PU&dib_tag=se&keywords=jalvayu&qid=1712912129&sprefix=jalvayu%2Caps%2C226&sr=8-7" target='_blank'><button className="w-full">Buy Now</button></a>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Products