import { Link } from 'react-router-dom';


function Footer() {
    return (
        <div className=" flex md:justify-around flex-wrap  px-4 md:px-20 py-12 bg-sky-200 " id="contect">
            <div className="p-4">
                <h2 className="p-1  text-3xl font-semibold">Policies</h2>
                <Link to="privacy-policy" ><p className="p-1 text-xl">Privacy Policy</p></Link>
                <Link to="/term-of-use" ><p className="p-1 text-xl">Term of use</p></Link>
            </div>
            <div className="p-4">
                <h2 className="p-1  text-3xl font-semibold">Address</h2>
                <p className="p-1 text-xl w-40">Bhamashah Techno hub Sansthan Path, Jhalana Gram, Jaipur</p>
                <p className="p-1 text-xl">jaipur,(Rajasthan)</p>
            </div>
            <div className="p-4">
                <h2 className="p-1 text-3xl font-semibold">Social links</h2>
                <div className="flex p-2">
                <a className="pr-3 pb-3 pt-3" href="https://www.facebook.com/profile.php?id=61557843660478"><img src={require("./images/facebook.svg").default} alt="" /></a>
                    <a className="pr-3 pb-3 pt-3" href="https://www.instagram.com/jalvayu.co/"><img src={require("./images/instagram.svg").default} alt="" /></a>
                    <a className="px-3 py-3" href="https://www.youtube.com/channel/UCEwcWHibgBv4yw_kcs1TKww"><img src={require("./images/youtube.svg").default} alt="" /></a>
                    <a className="p-2 py-3 " href="https://www.linkedin.com/company/102232968/admin/feed/posts/"><img src={require("./images/linkedin.svg").default} alt="" />
</a>
                </div>
                <div className='py-8'>
                <div>A product of :</div>
                <div className='text-2xl font-light py-2'>Returno fintech pvt. ltd.</div>
            </div></div>
        </div>
    )
}

export default Footer