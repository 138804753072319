import React from 'react'
import HeroSection from "./HeroSection";
import Products from "./Products";

function Home() {
  return (<>
    <HeroSection />
    <Products />
    <div>
    <img className='p-4' src={require("./images/Purple and Blue 3d Modern Futuristic Technology Pitch Deck Presentation.jpg")} alt="" />
 </div>
    </>
  )
}

export default Home