import React from 'react'
import { Link } from 'react-router-dom';


function PrivacyPolicy() {
  return (
 <div className='mt-28 px-8 py-4 text-sm'>
    <h3 className="p-2 font-semibold">INTRODUCTION</h3>
    <p className="p-2">Returno Fintech Private Limited (“Company” or “we” or “us” or “our”) is committed to protecting the privacy of every information or data that we collect from the users (“User(s)”) who access our website https://www.Jalvayu.co (“Website”). Protecting our Users’ data is our utmost priority and responsibility. We have constructed this privacy policy (“Policy”) to help you to understand how we collect, use, disclose, maintain and transfer your Information (as defined hereinbelow). 
By visiting/accepting this Policy you understand and agree to the collection, use, sharing and processing of your Personal Information (as defined hereinbelow) in accordance with this Policy. If you do not agree with the provisions of this Policy, you may opt-out and stop using the Website at any time to prevent further use of the information shared by you. In case you are sharing any Personal Information of any other individual, you confirm that the said individual is aware and has consented to share such information as per the terms of this Policy.
This Policy is extended to all the Users on our Website as stated hereinafter in the Policy. This Policy was last updated on 12 March 2024
</p>
<h3 className="p-2 font-semibold">SCOPE</h3>
<p className="p-2 fon">We collect your Personal Information and third-party data only to the extent mentioned hereinafter in this Policy. Your data is handled in compliance with the legal prerequisite of the prevalent laws of India. We ensure that the Personal Information shared with us through the Website or other third party sources adheres to the best industry standard to uphold the fundamental right towards data privacy of our Users.
The current Policy dictates the data handling practices undertaken by the Company as on the date mentioned above. We reserve the right to amend the Policy from time to time. We recommend you to frequently read the Policy to understand the latest amendments or the updated Policy. Your continued use of our Website thereafter will imply your unconditional acceptance of such updates to this Policy.
</p>
<h3 className="p-2 font-semibold">CONSENT</h3>
<p className="p-2">By accessing or using our Website, you expressly consent to our terms of data handling mechanism mentioned in this Policy. You further consent to the disclosure of your Personal Information following this updated Policy. This Policy shall be deemed to be incorporated into the terms of use of the Website for Users (“Terms of Use”) and shall be read in addition to the Terms of Use.
Unless otherwise mentioned in this Policy, we do not share any of your Information (as defined hereinbelow) with any third party without seeking your prior consent. Any Information furnished to a third-party is done following this Policy and within the legal prerequisites of the governing laws.
</p>
<h3 className="p-2 font-semibold">INFORMATION CATEGORY</h3>
<p className="p-2">The following two types of information (“Information”) are collected through our Website: </p>
<p className="p-2">Personal Information:
The Information Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 defines “Personal Information” as any information that relates to a natural person, which, either directly or indirectly, in combination with other information available or likely to be available with a body corporate, is capable of identifying such person. The following form of personal information (“Personal Information”) is collected through our Website:
Personal Information collected from the User shall include any and every information that such User discloses to the Company and which may be used to identify the User, directly or indirectly. Such information may include but not be limited to the first name, last name, phone number, address, location, email ID, or any other Personal Information submitted by the User or any person on behalf of the User, directly through our Website while placing order.
If you choose to connect with us throughyou can do this by “contact us” which is mentioned on our Website, we will retain such Information which you may have submitted to resolve any dispute, address query, provide User redressal, and troubleshoot problems.
Non-Personal Information:
The following form of Information is termed to be non-personal information (“Non-Personal Information”).
Non-Personal Information means information that is not limited to determine the identity of any individual or business. Such Information includes but is not limited to your browser type, the URL of the previous websites you visited, your internet service provider (ISP), operating system, and your Internet Protocol (IP) address; connection information; screen resolution, usage statistics, device log and event information, logs, keywords and metadata of incoming and outgoing calls and messages.
We may collect information that you voluntarily provide, such as information included in response to a questionnaire or a survey conducted by the Company or any individual or business who is given the responsibility to act on behalf of the Company. We may use this data to tailor our Website, to improve the performance or content, and to provide you support and troubleshoot problems within the permissible corner of the governing law
</p>
<h4 className="p-2 font-semibold">HOW DO WE COLLECT AND RECEIVE INFORMATION </h4>
<p className="p-2">We may collect your Information when you register on the Website or when you contact or email us on jalvayu6@gmail.com provided on the Website. Such information may include but is not limited to your name, your phone number, email address, location, medical information, shop name (as applicable).
We may collect and retain your financial information, with your prior consent, when you pay us through the Website upon availing our services. Such information may include but is not limited to your location and billing information (collectively referred to as “Financial Information”).
We may collect your Information when you provide testimonials based on our services to be published on the Website. Such testimonials shall be used only to promote the Company and its services.
We may collect Information that may be shared by you through any of the given contact details of the Company or its social media handles.
We may collect your Information when you participate in the surveys and polls conducted by the Company. It is voluntary to participate in these surveys or polls. The Information is gathered from surveys and polls and used to make improvements to our Website.
We may, in the future, organize contests on the Website. To participate in such contests, we may collect such Information as may be required. In addition to that, we may share your Information to such third party as necessary to the extent necessary for the fulfilment of price and/or any other aspect of the contest.
The Company collects device and connection-specific information when you access, or use our Website. This includes Information like hardware model, operating system information, signal strength, app version, browser information, connection information including phone number, mobile operator or ISP, language and time zone, and IP address.
</p>
<h4 className="p-2 font-semibold">HOW DO WE USE YOUR INFORMATION</h4>
<p className="p-2">We may use your Information to improve the functionality of our Website in identifying usage trends and to improve our Website.
We may use Information like your full name, address, phone number, email id, location, or any other contact information for raising invoices.
We may use your Information for administering our services and diagnose technical problems on the Website.
We may use your Information to send you communications that you have requested or that may be of interest to you by way of emails or contact number, or any other mode of communication.
We may contact you on the phone number provided by you to respond and communicate your queries and other concerns. We may also communicate with you about our current services, new services and opportunities that may be available to you.
We may use the Information to deliver target advertisements to the User.
We may use your IP address to analyse our traffic and to ease any technical complication which you may face while using our Website.
We may use your Information to communicate any changes in the Terms of Use and/or Privacy Policy.
We may use your Information for any other purpose with your consent.
</p>
<h4 className="p-2 font-semibold">SHARING OF INFORMATION</h4>
<p className="p-2">The Company does not rent, sell, or disclose any Personal Information about you with other people or non-affiliated entities, except with your consent, to provide services you have requested for, unless we believe that such action is necessary under the following circumstances:
to comply with the law, or legal process served on us;
protect and defend our rights and the enforcement of our agreements, to defend against legal claims; or as otherwise required by law. This may be done in response to a law enforcement agency's request; and
exercise or protect the rights, property, or personal safety of the Company, our Users, or others.
We may share some User’s Information (including but not limited to the name, address and prescriptions of the User) with our delivery agents to facilitate the delivery of the products.
In the event, if we expand our business, merge or amalgamate or acquire or restructure our business, we might require to share all or some of your Information with our affiliates. Under such circumstances, the Company would, to the extent possible, require the acquiring party to follow the practices described in this Policy. You acknowledge that such transfers may occur, and that any acquirer of the Company may continue to process your Personal Information as set forth in this Policy.
We may be required to share your Financial Information to a third party payment gateway provider. All Financial Information collected from you by such third party payment gateway providers will be used only for billing and payment processes. The Financial Information collected from you is transacted through secure digital platforms of approved payment gateways, which are under encryption, thereby complying with reasonably expected technology standards. The verification of the Financial Information shall be accomplished only by you through a process of authentication in which we shall have no role to play and hence, we shall bear no liability in relation to the same. We shall neither be liable nor responsible for any actions or inactions of the third party payment gateway providers or any breach of conditions, representations and warranties given by them. We shall also not be obligated to mediate or resolve any dispute or disagreement between you and such third party payment service providers.
The Company takes your right to privacy very seriously and other than as specifically stated in this Policy, will only disclose your Personal Information in the event it is required to do so by law, rule, regulation, law enforcement agency, governmental official, legal authority or similar requirements. The Company warrants that such Information will be disclosed only in accordance with applicable laws and regulations. 
</p>
<h4 className="p-2 font-semibold">THIRD-PARTY WEBSITES AND SERVICES</h4>
<p className="p-2">Our Website may provide you links to third-party websites. The Company has no control over any Information that is collected or sought by such third-party websites. We do not have any control over the data handling process or the content of such third-party websites. We neither represent nor warrant the privacy policy or terms of use of such third-party websites.
You further acknowledge and agree that we are not liable for any loss or damage which may be incurred by you as a result of the collection and/or disclosure of your Personal Information by external Websites, sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, products or other materials on, or available from such Websites, websites or resources.
The Company will not be accountable or responsible for any breach or misuse of data committed by any such third-party websites. These websites and resource providers may have their own privacy policies governing the collection, storage, retention and disclosure of your Personal Information that you may be subject to. We recommend that you enter the external Website or website and review their privacy policy.
We might engage service partners to perform certain activities on behalf of the Company to provide you access to our Website efficiently, thus we may require sharing your Information with such service partners. This data sharing is limited to the extent of the Information that is required to undertake such activities which the service partners are designated to perform. We do not represent nor warrant privacy policies and terms of use of such service partners. The Company will not be responsible for any privacy breach committed by such service partners.
 
COOKIES, THIRD-PARTY ANALYTICS, SPONSOR 
Cookies (“Cookies”) are small text files that are saved in your tablet/ PC or mobile phone’s hard drive (collectively knowns as “Devices”). Cookies help us to navigate through your online preferences, remember your preferences, and in general to provide you an amplified user experience. The primary intent of using Cookies is to enhance your Website experience and not to track any of our User’s online activities.
The Website may ask your permission to place Cookies on your Devices. In case of non-acceptance of Cookies, a certain user experience might get obstructed. Further, we may also allow third- parties to access the Cookies placed by us on your Devices for them to serve advertisements on our Website.
By accepting the Cookies, you consent us to place the Cookies in your Devices and use them for the purpose stated in this Policy.
We may use any third-party analytic tool to monitor and analyse your usage of our Website which will in return help us to enhance your experience. We may share your Information or such Information may be directly collected by such tools or any other similar third-party that we may use to help us to evaluate the use of our services.
</p>
<h3 className="p-2 font-semibold">PROTECTION OF INFORMATION</h3>
<p className="p-2">The Company takes utmost care towards safeguarding the Information provided by you through our Website by storing such information in the jurisdiction of India. We ensure that your choices for its intended use are honoured. The protection measures include but are not limited to any kind of alteration, or loss, or misuse, or unlawful processing of any Information. Only authorised personnel are allowed to access any Information that is sought through our Website, Customer Care, or otherwise.
The Company has implemented physical, electronic, and procedural safeguards in order to protect the Information, including that the Information will be stored on secured servers and protected by secured networks to which access is limited to a few authorized employees and personnel. However, no method of transmission over the internet or method of electronic storage is 100% (hundred per cent) secure. Thus, we cannot guarantee any safety of your data accessed by any unauthorized person or any unintended breach from our end.
</p>
<h3 className="p-2 font-semibold">MINOR</h3>
<p className="p-2">The Website is not intended to attract anyone under the relevant age of consent to enter into binding legal contracts under the laws of their respective jurisdictions (“Minors”). We do not solicit or intend to collect any Information from any Minor. However, in case our Website is accessed by a Minor, we encourage parents and guardians to be involved in the online activities of Minor to ensure that no Personal Information is collected from a Minor without their prior consent.</p>
 <h3 className="p-2 font-semibold">LIMITED LIABILITY</h3>
 <p className="p-2">As mentioned above in this Policy, the Company will not be responsible for any privacy or data breach done by its service partner or third-party websites. Further, we shall not be held responsible for any loss, damage, or misuse of your Information, if such loss, damage, or misuse is attributable to any event that is beyond the reasonable control of the Company and shall include, without limitation, sabotage, fire, flood, explosion, acts of god, civil commotion, strikes, lockouts or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, civil disturbances, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption, and any other similar events not within the control of the Company (“Force Majeure Event”) and which the Company is not able to overcome.
At any point of time, our liability for any loss arising from a particular transaction carried out by you through the Website shall not exceed the consideration paid by you for that particular transaction.
</p>
 
 <h3 className="p-2 font-semibold">OPT-OUT </h3>
 <p className="p-2">If you wish to discontinue or remove or alter any of your Personal Information from our Website or otherwise, contact us through jalvayu6@gmail.com  We will retain your Information for as long as your account with the services is active and as needed to provide you the services. We shall not retain such Information for longer than is required for the purposes for which the Information may lawfully be used or is otherwise required under any other law for the time being in force.
If you wish to opt-out of receiving non-essential communications such as marketing-related information regarding the services, please send us an email at jalvayu6@gmail.com .
</p>
 </div>
  )
}

export default PrivacyPolicy