import { Carousel } from "flowbite-react"
import a from './images/1.svg';
import b from './images/2.svg';
// import c from './images/3.svg';


function HeroSection() {
  return (

    <div className="h-60 mt-32 px-4  sm:h-96 xl:h-[900px] 2xl:h-[800px] ">
      <Carousel>
      <img className="fill" src={a} alt="" />
        <img className="object-contain" src={b} alt="" />
        <img className="object-contain" src={require("./images/3.svg").default} alt="" />


      </Carousel>
    </div>


  ) 
}

export default HeroSection